@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SUIT Variable';
    src: url('./assets/font/SUIT-Variable.woff2') format('woff2');
    font-weight: 100 900;
    font-style: normal;
}

@media print {
    /* 헤더, 푸터, 불필요한 요소 제거 */
    body {
        margin: 0;
        padding: 25mm;
        -webkit-print-color-adjust: exact;
    }
    @page {
        /* size: A4; */
        margin: 0;
    }
    .header-print,
    .footer-print {
        display: none !important;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'SUIT Variable', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #e0e0e0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/**********/
.wrapper {
    @apply w-[1400px] m-auto;
}

.wrapperHeight {
    @apply min-h-screen;
}

.handWrapper {
    @apply max-w-[1000px] w-full m-auto;
}

/****************** input style ******************/
.inputErrorStyle {
    @apply border-status-error border text-status-error bg-primary-white focus:outline-none;
}
.inputDefaultStyle {
    @apply focus:outline focus:outline-primary-purple focus:bg-primary-white placeholder:text-secondary-lGrayDark bg-secondary-mGrayLight;
}
.inputCommonStyle {
    @apply p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black;
}

.inputDisabledStyle {
    @apply border-secondary-mGrayDark border text-secondary-lGrayDark bg-secondary-mGrayLight focus:outline-none;
}

/* ApexCharts 애니메이션 비활성화 */
#analysis .apexcharts-canvas {
    animation: none !important;
}

#analysis .apexcharts-series path {
    animation: none !important;
    transition: none !important;
}

#analysis .apexcharts-series-markers {
    animation: none !important;
    transition: none !important;
}

#analysis .apexcharts-tooltip {
    animation: none !important;
}

/* 글자 깜빡임 애니메이션 */

@keyframes blink {
    0%,
    100% {
        opacity: 1; /* 완전히 보임 */
    }
    50% {
        opacity: 0.5; /* 반투명 */
    }
}

.animate-blink {
    animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
}
