/* ********** swal 수정 ********** */
.swal2-container {
    z-index: 9999; /* 다른 요소 위에 표시 */
}

@keyframes softFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.swal2-toast {
    transition: all 0.3s ease-out;
    will-change: transform, opacity; /* 성능 최적화 */
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

.swal2-container .swal2-popup.custom-fade-in {
    animation: fadeInDown 0.5s ease-out;
}

.swal2-container .swal2-popup.custom-fade-out {
    animation: fadeOutUp 0.5s ease-out;
}
