/* ********** apex chart 수정 ********** */

#main-area-chart .apexcharts-tooltip {
    /* display: none !important; */
    background: none !important;
    border: none !important;
    color: #ffffff !important;
    width: 120%;
    margin-left: -60px;
    margin-top: -50px;
    font-weight: bold;
    font-size: 10px;
    box-shadow: none !important;
}

#main-area-chart .apexcharts-xaxistooltip {
    background: #5e6eff !important;
    border: none !important;
    color: #ffffff !important;
    margin-top: 8px;
    padding: 2px 6px !important;
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px !important;
}

#main-area-chart .apexcharts-xaxistooltip-bottom:before {
    display: none !important;
}
#main-area-chart .apexcharts-xaxistooltip-bottom:after {
    display: none !important;
}

/* .apexcharts-canvas {
    height: 300px !important;
} */

#main-bar-chart .apexcharts-tooltip {
    /* display: none !important; */
    background: none !important;
    border: none !important;
    color: #ffffff !important;
    margin-top: -25px !important;
    margin-left: -20px !important;
    font-size: 18px;
    box-shadow: none !important;
}

#main-bar-chart .apexcharts-xaxistooltip {
    background: #5e6eff !important;
    border: none !important;
    color: #ffffff !important;
    margin-top: 8px;
    padding: 2px 14px !important;
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px !important;
}

#main-coupleBar-chart .apexcharts-tooltip {
    /* display: none !important; */
    background: none !important;
    border: none !important;
    color: #ffffff !important;
    /* width: 110%; */
    margin-left: -23px;
    margin-top: -10px;
    font-weight: bold;
    /* font-size: 10px; */
    box-shadow: none !important;
}

#analysis-bar-chart .apexcharts-xaxistooltip-bottom:before {
    display: none !important;
}
#analysis-bar-chart .apexcharts-xaxistooltip-bottom:after {
    display: none !important;
}

#analysis-radar-chart .apexcharts-legend-marker {
    margin-right: 5px; /* 마커와 텍스트 간 간격 */
}

#analysis-area-chart .apexcharts-tooltip {
    /* display: none !important; */
    background: none !important;
    border: none !important;
    color: #ffffff !important;
    width: 110%;
    margin-left: -20px;
    margin-top: -10px;
    font-weight: bold;
    font-size: 10px;
    box-shadow: none !important;
}

#analysis-area-chart .apexcharts-xaxistooltip {
    background: #5e6eff !important;
    border: none !important;
    color: #ffffff !important;
    margin-top: 8px;
    padding: 2px 6px !important;
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px !important;
}

#analysis-area-chart .apexcharts-xaxistooltip-bottom:before {
    display: none !important;
}
#analysis-area-chart .apexcharts-xaxistooltip-bottom:after {
    display: none !important;
}

#graph-bar-chart .apexcharts-tooltip {
    /* display: none !important; */
    background: none !important;
    border: none !important;
    color: #ffffff !important;
    margin-left: -18px;
    margin-top: -30px;
    box-shadow: none !important;
}

#graph-bar-chart .apexcharts-xaxistooltip {
    background: #5e6eff !important;
    border: none !important;
    color: #ffffff !important;
    margin-top: 8px;
    padding: 2px 14px !important;
    border-radius: 5px !important;
}

#graph-bar-chart .apexcharts-xaxistooltip-bottom:before {
    display: none !important;
}
#graph-bar-chart .apexcharts-xaxistooltip-bottom:after {
    display: none !important;
}
