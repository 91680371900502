/* 스크롤바 수정 */
.scroll-dropdown-adjust {
  position: relative; /* 드롭다운 요소의 위치를 상대적으로 설정 */
  overflow-y: auto; /* 세로 스크롤이 필요할 때 스크롤을 허용 */
  /* padding-right: 15px; 오른쪽에 패딩을 추가하여 스크롤 바를 안쪽으로 밀어 넣음 */
  /* margin-right: -5px; 스크롤 바를 왼쪽으로 약간 이동 */
}

/* 스크롤 바 전체에 대한 스타일 지정  */
/* background-color: #e7e7e7; */

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* 스크롤 바의 색상 지정 */
/* 투명한 border-right로 여백 생성, 배경이 padding 영역 내에만 그려지도록 함 */
::-webkit-scrollbar-thumb {
  background-color: #5651e3;
  border-radius: 8px;
  /* border: 3px solid rgba(0, 0, 0, 0); */
  background-clip: padding-box;
}
/* 스크롤 바 호버 시 색상 지정 */
::-webkit-scrollbar-thumb:hover {
  background-color: #1f345a;
}

/* 부분적으로 스크롤바 디자인 */

.custom-scroll-container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* 스크롤 바의 색상 지정 */
/* 투명한 border-right로 여백 생성, 배경이 padding 영역 내에만 그려지도록 함 */
.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 8px;
  /* border: 3px solid rgba(0, 0, 0, 0); */
  background-clip: padding-box;
}
/* 스크롤 바 호버 시 색상 지정 */
.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #1f345a;
}
